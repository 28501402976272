import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';
import introToWf from '../images/intro-to-wf.png';

const ChangeLog01 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.0.0" intro="Windframe initial release.">
        <Image src={introToWf} />
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog01;
