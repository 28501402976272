import React from 'react';
import { Wrapper, Image, ChangelogSection } from './Cards.style';

import crm1 from '../images/crmdashboard.png';
import crm2 from '../images/crmdashboard2.png';
import crypto1 from '../images/crypto.png';
import crypto2 from '../images/crypto2.png';
import sidebar from '../images/sidebar.png';
import table from '../images/table10.png';
import table2 from '../images/table11.png';

const changelogData = {
  new: [
    'Tailwind CSS admin templates',
    'Project autosave functionality',
    'Publish saved project with sharable links',
  ],
  improvements: [
    'Improved user interface: Projects page redesign',
    'Improved saved project history',
    'User flow refactor',
    'Improved user Interface'
  ],
  fixes: [
    "User logout error fix",
  ]
};
const ChangeLog11 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.3.0" changelogSections={changelogData}>
        <p>Dashboards</p>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={crm1} />
          <Image src={crm2} />
          <Image src={crypto1} />
          <Image src={crypto2} />
        </div>
        <p>Tables</p>
        <div>
          <Image src={table} />
          <Image src={table2} />
        </div>
        <p>Sidebar</p>
        <Image src={sidebar} />
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog11;
