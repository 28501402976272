import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';
import newDashboard from '../images/dashboardnew3.png';
import integr from '../images/integr.png';
import integration from '../images/integration.png';
import integration2 from '../images/integrationpage2.png';

const changelogData = {
  new: [
    'Projects save history',
    'Tailwind CSS admin integration pages, billing pages, team management pages, and notification setting pages',
  ],
};

const ChangeLog9 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.2.2" changelogSections={changelogData}>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={newDashboard} />
          <Image src={integration2} />
          <Image src={integration} />
          <Image src={integr} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog9;
