import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../../components/Layout/Layout';

import ChangeLog from '../../components/ChangeLog';

const Windframe = () => {
  return (
    <Layout>
      <Helmet>
        <title>Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder</title>
        <meta name="title" content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder" />
        <meta
          name="description"
          content="Create breathtaking websites using our No code tailwindcss drag and drop builder in record time. Create a website or build an online presence easily for your brand or business using windframe’s 120+ easily editable blocks."

        />
        <meta
          property="og:title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          property="og:description"
          content="Create breathtaking websites using our No code tailwindcss drag and drop builder in record time. Create a website or build an online presence easily for your brand or business using windframe’s 150+ easily editable blocks."
        />
        <meta
          property="twitter:title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          property="twitter:description"
          content="Create breathtaking websites using our No code tailwindcss drag and drop builder in record time. Create a website or build an online presence easily for your brand or business using windframe’s 150+ easily editable blocks."
        />
        <link rel="canonical" href="https://windframe.dev/" />
        <meta property="og:url" content="https://windframe.dev/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <ChangeLog />
    </Layout>
  );
};

export default Windframe;
