import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection
} from './Cards.style';
import template24 from '../images/template24.png';
import template25 from '../images/template25.png';
import template26 from '../images/template26.png';
import template27 from '../images/template27.png';
import template28 from '../images/template28.png';
import template29 from '../images/template29.png';

const changelogData = {
  new: ['Dark theme Tailwind CSS templates'],
  imrprovements:[
    "Improved project preview UI",
    "Templates design update"
  ],
  fixes:[
    "Editor's canvas state bug fixes"
  ]
};

const ChangeLog16 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.2" changelogSections={changelogData}>
        <div className="md:grid gap-2  grid-cols-2 place-items-center">
          <Image src={template24} />
          <Image src={template25} />
          <Image src={template26} />
          <Image src={template27} />
          <Image src={template28} />
          <Image src={template29} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog16;
