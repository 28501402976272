import React from 'react';

import ChangeLog1 from './ChangelogCards/ChangeLog1';
import ChangeLog2 from './ChangelogCards/ChangeLog2';
import ChangeLog3 from './ChangelogCards/ChangeLog3';
import ChangeLog4 from './ChangelogCards/ChangeLog4';
import ChnageLog5 from './ChangelogCards/ChangeLog5';
import ChangeLog6 from './ChangelogCards/ChangeLog6';
import ChangeLog7 from './ChangelogCards/ChangeLog7';
import ChangeLog8 from './ChangelogCards/ChangeLog8';
import ChangeLog9 from './ChangelogCards/ChangeLog9';
import ChangeLog10 from './ChangelogCards/ChangeLog10';
import ChangeLog11 from './ChangelogCards/ChangeLog11';
import ChangeLog12 from './ChangelogCards/ChangeLog12';
import ChangeLog13 from './ChangelogCards/ChangeLog13';
import ChangeLog14 from './ChangelogCards/ChangeLog14';
import ChangeLog15 from './ChangelogCards/ChangeLog15';
import ChangeLog16 from './ChangelogCards/ChangeLog16';
import ChangeLog17 from './ChangelogCards/ChangeLog17';
import ChangeLog18 from './ChangelogCards/ChangeLog18';

import { MainWrapper, Intro } from './ChangelogCards/Cards.style';

const ChangeLog = () => {
  return (
    <>
      <MainWrapper>
        <Intro>
          <h1>Windframe Changelog</h1>
          <p>
            Welcome to the Windframe changelog! This page provides a comprehensive history of all the changes that have been made to Windframe, including bug fixes, new features, and improvements.
          </p>
          <p>
            Click <a href="https://windframe.devwares.com">here</a> to try it out.
          </p>
          <p>
            If you have any questions, please visit our support page or reach out to our team at contact@devwares.com. We're always here to help.
          </p>
        </Intro>

        <main>
          <ChangeLog18 />
          <ChangeLog11 />
          <ChangeLog12 />
          <ChangeLog13 />
          <ChangeLog10 />
          <ChangeLog9 />
          <ChangeLog14 />
          <ChangeLog15 />
          <ChangeLog8 />
          <ChangeLog7 />
          <ChangeLog6 />
          <ChangeLog17 />
          <ChangeLog16 />
          <ChnageLog5 />
          <ChangeLog4 />
          <ChangeLog3 />
          <ChangeLog2 />
          <ChangeLog1 />
        </main>
      </MainWrapper>
    </>
  );
};

export default ChangeLog;
