import React from 'react';
import { Wrapper, Image, ChangelogSection } from './Cards.style';

import pricing1 from '../images/pricing.png';
import pricing2 from '../images/pricing2.png';
import pricing3 from '../images/pricing3.png';

const changelogData = {
  new: ['Tailwind CSS templates', 'Responsive classes for template elements prop settings'],
  imporovements: [
    'Editor updates: Convert buttons and text to hyperlinks',
    'Improved export functionality',
    'Templates re-arrangements for accessibility',
  ],
};

const ChangeLog8 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.6" changelogSections={changelogData}>
        <div>
          <Image src={pricing1} />
          <Image src={pricing2} />
          <Image src={pricing3} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog8;
