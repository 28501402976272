import React from 'react';
import { Wrapper, Image, ChangelogSection } from './Cards.style';

import template17 from '../images/template17.png';
import template18 from '../images/template18.jpg';
import template19 from '../images/template19.png';
import template20 from '../images/template20.png';
import template21 from '../images/template21.png';

const changelogData = {
  new: [
    'Export projects to HTML file.',
    'Template block code snippet copy',
    'Team Collaboration: Invite and manage users accounts, projects collaboration',
    'Tailwind CSS templates',
  ],
  improvements: ['Improved clone elements and sections'],
  fixes: [
    "Users' accounts persisitence bug fixes"
  ],
};

const ChangeLog4 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.0" changelogSections={changelogData}>
        <div className="md:grid gap-2  grid-cols-2 place-items-center">
          <Image src={template17} />
          <Image src={template18} />
          <Image src={template19} />
          <Image src={template20} />
        </div>
        <Image src={template21} />
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog4;
