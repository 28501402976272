import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';

import sidebar from '../images/sidebar.png';
import table from '../images/table10.png';
import table2 from '../images/table11.png';

const changelogData = {
  new: ['Tailwind CSS admin tables and sidebar templates'],
  improvements: [
    "Responsiveness: Changed some screen viewport sizes",
    "Improved projects preview",
    "Improved project exported code",
    "Children nodes positioning bug fix"
  ]
};

const ChangeLog13 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.2.4" changelogSections={changelogData}>
        <div>
          <Image src={table} />
          <Image src={table2} />
        </div>
        <Image src={sidebar} />
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog13;
