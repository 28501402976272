import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';
import template5 from '../images/template5.png';
import template6 from '../images/template6.jpg';
import template7 from '../images/template7.png';
import template8 from '../images/template8.jpg';
import template9 from '../images/template9.png';
import template10 from '../images/template10.png';
import template11 from '../images/template11.jpg';
import template12 from '../images/template12.jpg';
import template13 from '../images/template13.jpg';
import template14 from '../images/template14.jpg';
import template15 from '../images/template15.jpg';
import template16 from '../images/template16.jpg';

const changelogData = {
  new: [
    'Save project feature for projects retrieval',
    'Elements Animation and Animation Settings',
    '20+ Tailwind Block templates',
  ],
  improvements: [
    "Canvas responsiveness",
    "Windframe templates id conflicts update"
  ]
};

const ChangeLog3 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.0.2" changelogSections={changelogData}>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={template5} />
          <Image src={template6} />
          <Image src={template7} />
          <Image src={template8} />
          <Image src={template9} />
          <Image src={template10} />
          <Image src={template11} />
          <Image src={template12} />
          <Image src={template13} />
          <Image src={template14} />
          <Image src={template15} />
          <Image src={template16} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog3;
