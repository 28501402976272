import React from 'react';
import { Wrapper, Image, ChangelogSection } from './Cards.style';

import template1 from '../images/template1.png';
import template2 from '../images/template2.png';
import template3 from '../images/template3.png';
import template4 from '../images/template4.png';

const changelogData = {
  new: [
    'Clone elements and sections',
    'Tailwind CSS header templates',
    'Tailwind CSS classes: add and remove classes in elements',
  ],
  fixes: [
    "Editor's canvas templates rendering error fix",
    "Templates: Incorrect templates loaded to canvas issues fix"
  ]
};

const ChangeLog2 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.0.1" changelogSections={changelogData}>
        <div className="md:grid gap-2  grid-cols-2">
          <Image src={template1} />
          <Image src={template2} />
          <Image src={template3} />
          <Image src={template4} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog2;
