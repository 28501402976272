import React from 'react';
import styled from 'styled-components';

export const MainWrapper = styled.div`
width: 85%
max-width: 650px;
margin: 3em auto;

@media (max-width: 425px) {
  width: 100%;
  margin: 2rem auto;
  padding: 0 12px;
}
`;

export const Intro = styled.div`
  h1 {
    font-size: 2.3rem;
    font-weight: bold;
    padding-bottom: 5px;

    @media (max-width: 425px) {
      font-size: 1.8rem;
    }
  }

  p {
    padding-bottom: 4px;
    a {
      color: blue;
    }
  }
`;

export const ChangelogSection = styled(({ version, intro, changelogSections = {}, children }) => {
  return (
    <Body>
      {version && <Title>v{version}</Title>}

      {intro && <p>{intro}</p>}

      {changelogSections &&
        Object.entries(changelogSections)?.map(([section, sectionData]) => (
          <div className="mt-3">
            <p className="font-bold pb-1">
              {section?.charAt(0)?.toUpperCase() + section?.slice(1)?.toLowerCase()}
            </p>

            <ul>
              {sectionData &&
                sectionData?.length > 0 &&
                sectionData?.map(d => <Paragraph>{d}</Paragraph>)}
            </ul>
          </div>
        ))}

      <div className="mt-3 children-section">{children}</div>
    </Body>
  );
})`
  .children-section p {
    font-weight: bold;
  }
`;

export const Wrapper = styled.div`
  margin: 3rem auto;

  @media (max-width: 425px) {
    width: 100%;
    margin: 1.5rem auto;
  }
`;

export const Month = styled.div`
  flex: 2;
  text-align: right;
  padding-right: 3rem;

  span {
    color: #8e8e8e;
    text-transform: capitalize;
  }

  @media (max-width: 425px) {
    text-align: left;
    padding: 0px;
  }
`;

export const Body = styled.div`
  flex: 8;
  padding-bottom: 2rem;
  border-bottom: 1px solid #8e8e8e54;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  @media (max-width: 425px) {
    font-size: 1.5rem;
  }
`;

export const Badge = styled.div`
  background-color: #3329e0;
  border-radius: 2rem;
  margin: 1rem 0rem;
  font-size: 14px;
  padding: 0.25rem 1rem;
  width: fit-content;
  color: white;
`;

export const Paragraph = styled.li`
  word-spacing: 0.1rem;
  margin-bottom: 3px;
  list-style: disc;
  list-style-position: inside;
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const AuthorImage = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const AuthorName = styled.p`
  color: #8e8e8e;
  margin: 0px 0px 0px 0.5rem;
`;

export const Image = styled.img`
  width: 100%;
  margin: 8px auto;
  object-fit: cover;
`;
