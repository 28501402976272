import React from 'react';
import { Wrapper, Image, ChangelogSection } from './Cards.style';
import password from '../images/password.png';
import payment from '../images/paymen.png';
import profile from '../images/profile.png';
import sessions from '../images/sessions.png';

const changelogData = {
  new: [
    'Tailwind CSS admin templates: ersonal profile pages, business profile pages, password pages and sessions pages',
  ],
  improvements: [
    "Editor's canvas updates"
  ],
  fixes: [
    'Select pagebuilder settings fix',
    'Element class names delete fix',
    'Link elements issue fix',
    'Import templates funtionality issue fix',
  ],
};

const ChangeLog15 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.2.0" changelogSections={changelogData}>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={password} />
          <Image src={payment} />
          <Image src={profile} />
          <Image src={sessions} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog15;
