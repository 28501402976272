import React from 'react';
import {
  Wrapper,
  ChangelogSection,
  Image,
} from './Cards.style';

import crm1 from '../images/crmdashboard.png';
import crm2 from '../images/crmdashboard2.png';
import crypto1 from '../images/crypto.png';
import crypto2 from '../images/crypto2.png';

const changelogData = {
  new: ['Tailwind CSS admin dashboard templates: CRM, crypto dashboards'],
  improvemnents: [
    "Improved user interface",
    "Updated canvas size for screen viewports",
    "Added more comments to templates"
  ],
  fixes: [
    "Project load error on login fix",
    "Login error fix"
  ]
};

const ChangeLog12 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.2.5" changelogSections={changelogData}>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={crm1} />
          <Image src={crm2} />
          <Image src={crypto1} />
          <Image src={crypto2} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog12;
