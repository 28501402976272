import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';
import template22 from '../images/template22.png';
import template23 from '../images/template23.png';

const changelogData = {
  new: ['Dark theme Tailwind CSS templates'],
  improvements: [
    'Responsive development: new viewports (screen sizes) and canvas zoom',
    'Improved user interface ',
  ],
  fixes: [
    "Projects save: unending save state on error bug fixes",
    "Incorrect templates load errors fix"
  ]
};

const ChnageLog5 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.1" changelogSections={changelogData}>
        <div className="md:grid gap-2  grid-cols-2 place-items-center">
          <Image src={template22} />
          <Image src={template23} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChnageLog5;
