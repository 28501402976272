import React from 'react';
import { Wrapper, ChangelogSection } from './Cards.style';

const changelogData = {
  new: ['Templates search: search bar to easily search for templates on windframe'],
  improvements: [
    'Improved Undo and redo functionality changes made to project on canvas',
    'UI updates',
    'Templates design updates',
  ],
  fixes: ['Import template bug fix', "Projects preview and exported code HTML attributes bug fix"],
};

const ChangeLog18 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.3.1" changelogSections={changelogData}></ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog18;
