import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';

import banking from '../images/banking.png';
import analytics from '../images/web-analytics.png';

const changelogData = {
  new: ['Tailwind CSS Dashboards and Admin templates'],
  imporovements: [
    "Save history: added delete history",
    "Improved editor's canvas element nodes view in layers",
    "Improved export to HTML functionality"
  ],
  fixes: [
    "Directional elements' padding and maargin issue fix",
  ]
};

const ChangeLog10 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.2.3" changelogSections={changelogData}>
        <div className="md:grid grid-cols-2 gap-2 place-items-center">
          <Image src={banking} />
          <Image src={analytics} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog10;
