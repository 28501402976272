import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection,
} from './Cards.style';

import companyProfile from '../images/company_profile.png';
import customers from '../images/customers.png';
import fileUpload from '../images/fileup.jpg';
import notificationPage from '../images/notificationSettingsPage.png';

const changelogData = {
  new: [
    'Templates preview',
    'Tailwind CSS admin templates: CRM pages, business profile pages, session pages, file upload  pages and notification setting pages',
  ],
  improvements: [
    'Import templates: added support for jsx templates and CSS configs',
    'Improved projects save and deletion functionality'
  ]
};

const ChangeLog14 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.2.1" changelogSections={changelogData}>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={companyProfile} />
          <Image src={customers} />
          <Image src={fileUpload} />
          <Image src={notificationPage} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog14;
