import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection
} from './Cards.style';

import card1 from '../images/card3.png';
import card2 from '../images/card4.png';
import cart1 from '../images/cart1.png';
import cart2 from '../images/cart2.png';
import review from '../images/review.png';

const changelogData = {
  new: [
    'Import Tailwind CSS templates to projects: edit, add and remove templates',
    'Tailwind CSS Admin and E-Commerce templates',
  ],
  fixes: [
    "Incorrect template elements props settings fix",
    "Canvas scroll fix",
  ]
};

const ChangeLog7 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.5" changelogSections={changelogData}>
        <p>Admin Cards</p>
        <div className="md:grid gap-2 grid-cols-2 place-items-center">
          <Image src={card1} />
          <Image src={card2} />
        </div>
        <p>E-Commerce Carts</p>
        <div>
          <Image src={cart1} />
          <Image src={cart2} />
        </div>
        <p>Review Card</p>
        <Image src={review} />
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog7;
