import React from 'react';
import { Wrapper, Image, ChangelogSection } from './Cards.style';

import tile1 from '../images/tile1.jpg';
import tile4 from '../images/tile4.jpg';
import card1 from '../images/card1.png';
import card2 from '../images/card2.jpg';
import dashboard1 from '../images/dashboard1.jpg';
import dashboard2 from '../images/dashboard2.png';

const changelogData = {
  new: ['Tailwind CSS Admin  (Dashboards, Tiles and Cards) templates'],
  improvements: ['Templates design update', 'Updated elements props settings'],
  fixes: [
    'Responsiveness: screen viewports and incorrect canvas scaling bug fix',
    'Templates load issues fix',
  ],
};

const ChangeLog6 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.4" changelogSections={changelogData}>
        <p>Admin Dashboards</p>
        <div className="md:grid grid-cols-2 place-items-center">
          <Image src={dashboard1} />
          <Image src={dashboard2} />
        </div>
        <p>Admin Cards</p>
        <div className="md:grid grid-cols-2 place-items-center">
          <Image src={card1} />
          <Image src={card2} />
        </div>
        <p>Admin Tiles</p>
        <div>
          <Image src={tile1} />
          <Image src={tile4} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog6;
