import React from 'react';
import {
  Wrapper,
  Image,
  ChangelogSection
} from './Cards.style';

import table3 from '../images/table3.jpeg';
import table4 from '../images/table4.jpeg';
import productInfo from '../images/productinfo1.png';
import productInfo2 from '../images/productinfo2.png';


const changelogData = {
  new: [
    'Tailwind CSS Admin and E-Commerce templates',
  ],
  improvements: [
    "Templates design update",
    "Improved save existing project flow",
    "Improved user interface for editor page"
  ],
  fixes: [
    "Pro templates: fixed blocking templates",
    "Project export: generated code bug fix"
  ]
};

const ChangeLog17 = () => {
  return (
    <Wrapper>
      <ChangelogSection version="1.1.3" changelogSections={changelogData}>
        <p>ECommerce Product Info</p>
        <div className="md:grid grid-cols-2 place-items-center">
          <Image src={productInfo} />
          <Image src={productInfo2} />
        </div>
        <p>Admin Tables</p>
        <div className="md:grid grid-cols-2 place-items-center">
          <Image src={table3} />
          <Image src={table4} />
        </div>
      </ChangelogSection>
    </Wrapper>
  );
};

export default ChangeLog17;
